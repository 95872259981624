import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../Models/User';
import {CoordonneesAuthentification} from "../Models/CoordonneesAuthentification";
import { CookieService } from 'ngx-cookie-service';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import {env} from '../Models/env'
@Injectable({
  providedIn: 'root'
})
export class UserService{
public userConnected:User;
updatedOrders = new Subject<Object[]>();
updatedUser = new Subject<any>();
orders:any[]=[];
ratings:any[]=[];email_reset:string=null;
  jwt: string;
  constructor(private http: HttpClient, private storage: Storage,private cookie:CookieService) {
  }
  private env = env.apiURL;


  checkExistCode(code){
    return this.http.get(`${this.env}verifExistanceOfCode/${code}`,)
  }
  AddUser(user: User) {

    
    return this.http.post(`${this.env}register?login=${user.coordonnees_authentification.login}&password=${user.coordonnees_authentification.password}&nom=${user.nom}&prenom=${user.prenom}
    &date_de_naissance=${user.date_de_naissance}&numero_de_telephone=${user.numero_de_telephone}&email=${user.email}`,
        {},{ withCredentials: true });
  }

  ModifyUser(id,user:User,jwt) {
    let login:string=user.coordonnees_authentification.login.toString();
    let password:string=user.coordonnees_authentification.password.toString();
    if(login=='' && password!='')
    return this.http.put(`${this.env}user/${id}?password=${password}&nom=${user.nom}&prenom=${user.prenom}
    &date_de_naissance=${user.date_de_naissance}&numero_de_telephone=${user.numero_de_telephone}&email=${user.email}`,
        user, { headers: {Authorization: 'Bearer ' +jwt}});
        else if(login!='' && password=='')
        return this.http.put(`${this.env}user/${id}?login=${login}&nom=${user.nom}&prenom=${user.prenom}
    &date_de_naissance=${user.date_de_naissance}&numero_de_telephone=${user.numero_de_telephone}&email=${user.email}`,
        user, { headers: {Authorization: 'Bearer ' +jwt}});
        else if(login=='' && password=='')
        return this.http.put(`${this.env}user/${id}?nom=${user.nom}&prenom=${user.prenom}
    &date_de_naissance=${user.date_de_naissance}&numero_de_telephone=${user.numero_de_telephone}&email=${user.email}`,
        user, { headers: {Authorization: 'Bearer ' + jwt}});
    return this.http.put(`${this.env}user/${id}?login=${login}&password=${password}&nom=${user.nom}&prenom=${user.prenom}
    &date_de_naissance=${user.date_de_naissance}&numero_de_telephone=${user.numero_de_telephone}&email=${user.email}`,
        user, { headers: {Authorization: 'Bearer ' +jwt}});
  }
  Login(user: User){
  //  //console.log(JSON.stringify(user.coordonnees_authentification));
    let coord;
coord=JSON.parse(JSON.stringify(user.coordonnees_authentification));
   // //console.log(coord);
    return this.http.post(`${this.env}login?login=${coord.login}&password=${coord.password}`, {},{ observe: 'response', withCredentials: true });
  }
  connected(jwt){//console.log(jwt)
    return this.http.get(`${this.env}connected`,  { headers: {Authorization: 'Bearer ' +jwt}});
  }
 
 logout(jwt)
  {    
    this.userConnected=null;
    
    return this.http.post(`${this.env}logout`, {},  { headers: {Authorization: 'Bearer ' +jwt}});
  }
  getuserByIdWithcoordonnes(login: string,jwt){
    return this.http.get<User>(`${this.env}getuser/id/${login}`,  { headers: {Authorization: 'Bearer ' +jwt}});
  }
  sendEmailForResetPWD(emaill){
  let emailsend={email:emaill}
    return this.http.post(`${this.env}sendEmailForResetCode`,emailsend);
  }
  ResetPassword(emaill,passwordd,code)
  {
    let emailsend={email:emaill,
    password:passwordd,code:code};//console.log(emailsend)
    return this.http.post(`${this.env}resetCoddepwd/${code}`,emailsend);

  }
  SignupGoogle(res:any)
  {
    return this.http.post(`${this.env}Signin/googleWebsite`,res,{ observe: 'response', withCredentials: true });
  }
  UploadImage(image:any,iduser:number){
    return this.http.post(`${this.env}uploadimguser/${iduser}`, image);

  }
  deleteImage(id,jwt){ //console.log(id);//console.log(jwt)
    return this.http.delete(`${this.env}image/${id}`,  { headers: {Authorization: 'Bearer ' +jwt}})
  }
  SearchLogin(login){
    return this.http.get(`${this.env}existlogin/${login}`)
  }
  SearchEmail(email){
    return this.http.get(`${this.env}existemail/${email}`)

  }
}
