import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FirebaseService } from './firebase.service';
import { env } from '../Models/env';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Injectable({
  providedIn: 'root'
})
export class PlatService {
  public plat:Object;public livraison=false;
 // private url =environment.apiUrl
  public cart:any[]=[];
  public taux_reduction=0;
  private prixTot=0;
  public prixtotwithOffer=0;
  private plats = [];
  private offre;
  public sorted=[];
  public quantite=0
  codeReduction:any;
  updatedSorted = new Subject<Object[]>();
  updatedQuantite = new Subject<number>();
  private env = env.apiURL;
  updatedPlats = new Subject<Object[]>();
  updatedCart = new Subject<Object[]>();
 PAYED = new Subject<Boolean>();
  updatedFiltred = new Subject<Object[]>();
  filtred=[]
  public longitude;
public latitude;public addressfix:any[]=[];
address
  updatedAdress = new Subject<Object[]>();distance:any;
  resto_isWorking=false; 
   updatedResearch=new Subject<any>();
  public PriceWithFee=0;
  private idcodeRed: any=null;
  longitudeOrigine: any;
  latitudeOrigine: any;
  prixlivraison: any;
  private latlng: any[];
  private latlngorg: any[];
  stripe: any;
  restoInfo:any;

  sur_place=false;
  emporte=false;
  constructor(private firebaseserv:FirebaseService,private http: HttpClient, private cookie:CookieService,private route:Router) { }

updateFilter(val){

  this.filtred=val.slice()
  
  this.updatedFiltred.next(this.filtred.slice())
}


  getPlats(){
    

    return this.plats.slice();
  }

resetCart(){this.prixtotwithOffer=0; this.PAYED.next(true)
  this.cart=[];this.updatedQuantite.next(0);
  this.updatedCart.next(this.cart.slice())
}

  removePlat(i){
    this.quantite=this.quantite-1
    this.updatedQuantite.next(this.quantite)
    if(this.cart[i].quantity-1==0){
    if(this.cart.length==1)
    this.cart=[]
    else
    this.cart.splice(i,1)}
    else
    this.cart[i].quantity-=1
    this.calculPrix()
    this.updatedCart.next(this.cart.slice())
  }
  addPlat(i){
  
   this.quantite=this.quantite+1
   this.updatedQuantite.next(this.quantite)
  // this.cart[i].quantity+=1
  //console.log(this.cart[i])
  this.cart.map((e:any,index)=>{if(index===i){
    
    e.quantity+=1
    return e
  }})

   
   this.calculPrix()
    this.updatedCart.next(this.cart.slice())
  }
  getCurrentPosAndSetAdress(){
    setTimeout(()=>{ navigator.geolocation.getCurrentPosition((resp)=>
    {
      this.latitude=resp.coords.latitude;
      this.longitude= resp.coords.longitude;
      this.getAddress(this.latitude,this.longitude).subscribe(
          (d: any)=>{
            this.address = d.display_name
          }
      );
      this.latlng=[this.latitude, this.longitude];//console.log(this.latlng);
      this.latlngorg=[this.latitudeOrigine,this.longitudeOrigine];
      this.distance=this.getDistance(this.latlng,this.latlngorg);
    })},2000)
  }

  getDistance(origin, destination) {
    // return distance in kilometers
    let lon1 = this.toRadian(origin[1]);
    let  lat1 = this.toRadian(origin[0]);
    let   lon2 = this.toRadian(destination[1]);
    let  lat2 = this.toRadian(destination[0]);

    let deltaLat = lat2 - lat1;
    let deltaLon = lon2 - lon1;
//console.log(deltaLat + "   "+ deltaLon);
    let a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
    let c = 2 * Math.asin(Math.sqrt(a)); //console.log(a + "   "+ c);

    let EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS;
  }
  toRadian(degree) {
    return degree*Math.PI/180;
  }
  calculPrix(){
    this.prixTot=0
    this.cart.map((p:any)=>{this.prixTot+=Number(p.prix)*p.quantity
    p.modificateurs.map((m:any)=>{;m.ingredients.map(ing=>{if(ing.checked) {this.prixTot+=Number(m.prix)*p.quantity}})
  })
    })
  }

  addPlatToCart(plat){
    this.quantite=this.quantite+1
    this.updatedQuantite.next(this.quantite)
    plat.quantity=1
    this.cart.push(JSON.parse(JSON.stringify(plat)))
    this.calculPrix()
    this.updatedCart.next(this.cart.slice())
  }
  fetchPlats(){
    this.http.get<Object[]>(`${this.env}nos_plats`).subscribe(data=>{this.plats=data
      this.plats.map(p=>{
        let rate=0
        p.ratings.map(r=>{
          rate+=r.note
        })
        if(p.ratings.length>0)
        p.rate=rate/p.ratings.length
        else
        p.rate=0
        return p
      })
      this.sorting()
   
      this.updatedPlats.next(this.getPlats());
    })
  }

  sorting(){
this.sorted=   this.plats. sort((left, right) => {
          if (left.rate < right.rate) return 1;
          if (left.rate > right.rate) return -1;
          return 0;
      }).slice()
      
      this.updatedSorted.next(this.sorted.slice())
  }

  providePlat(p){
    let plat;
    if(p!=null)
    this.plats.map(pl=>{if (pl .id===p.id) this.plat=pl})
    else
    this.plat=null
  }
  

getPrixTot(){
  return this.prixTot;
}

setPrixTot(prix){
  return this.prixTot=prix;
}
setidcodeRed(id){
  return this.idcodeRed=id;
}
getidcodeRed(){
  return this.idcodeRed;
}

reduirePrix(code,prix,jwt){
let prixtot=this.prixTot;
//console.log(prixtot);
return this.http.get(`${this.env}reduction/${code}/${this.prixtotwithOffer}`, { headers: {Authorization: 'Bearer ' +jwt}})
}



pay(token:any,jwt:any,method_payment:string,cartOffre:any[]){
  //console.log(this.addressfix);
  //console.log(addressfixString)

  console.log({token,method_payment:method_payment,cartOffre:cartOffre,
    longitude:this.longitude,latitude:this.latitude,address:this.address,
    prixtot:this.PriceWithFee,idCodRed:this.idcodeRed,codered:this.codeReduction,
    livraison:this.livraison,card:this.cart});
  if(token!=null)
      return  this.http.post<any[]>(`${this.env}payment/stripe`,Object.freeze({token:token.id,cartOffre:cartOffre,method_payment:method_payment,longitude:this.longitude,latitude:this.latitude,address:this.address,prixtot:this.PriceWithFee,idCodRed:this.idcodeRed,codered:this.codeReduction,livraison:this.livraison,card:this.cart}),{ headers: {Authorization: 'Bearer ' +jwt}})
    else 
      return this.http.post<any[]>(`${this.env}payment/stripe`,Object.freeze({token:null,cartOffre:cartOffre,method_payment:method_payment,longitude:this.longitude,latitude:this.latitude,address:this.address,prixtot:this.PriceWithFee,idCodRed:this.idcodeRed,codered:this.codeReduction,livraison:this.livraison,card:this.cart}),{ headers: {Authorization: 'Bearer ' +jwt}})

}
affectationRatingTouser(plat,rate,jwt){
return this.http.post(`${this.env}rating`,{plat_id:plat,note:rate}, { headers: {Authorization: 'Bearer ' +jwt}});
}
getRating(jwt){
  return this.http.get(`${this.env}getrating`, { headers: {Authorization: 'Bearer ' +jwt}})
}
AddAdress(i){
 this.address=i;
  this.updatedAdress.next(this.address);
}
getInforestaurent(){
return this.http.get(`${this.env}restaurent/info`);
}
RestaurentWork()
  { return this.http.get(`${this.env}restaurent/holiday`)}
getAddress(lat: number, long: number) {
        const addressdetails = 1;
        return this.http.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat.toString()}&lon=${long.toString()}&zoom=18&addressdetails=${addressdetails.toString()}`)}

GetPublicKeyStripe(){
  return this.http.get(`${this.env}stripe/get/publicKey`)
        }
}
