import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { HeaderComponentRoutingModule} from './Header-routing.module';
import {HeaderComponent} from './header.component';
import {FooterComponent} from '../footer/footer.component';


@NgModule({
  exports: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
HeaderComponentRoutingModule  ],
  declarations: [HeaderComponent, FooterComponent]
})
export class HeaderModule {}
