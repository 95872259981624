import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';

import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, 
import { Observable } from 'rxjs';
import { TypeScriptEmitter } from '@angular/compiler';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
      // Reference to order data list, its an Observable
  OrderRef: AngularFireObject<any>;   // Reference to order object, its an Observable too
  OrdersListRef: AngularFireList<any>;

  user_id: any;
  orders: any[]=[];
  order:any;
  ordersref:any;
  constructor(private db: AngularFirestore) {

   }
  AddOrders(order:any) {
 return    this.db.collection('posts').add(order);
  }   
  // Fetch Single order Object
  GetOrders(id) {


//this.ordersref=   this.db.collection('posts', ref => ref.where('user_id', '==', id))
let query = this.db.collection('posts');
this.orders=[]
query.get().subscribe(d => {    const a=[];
  d.forEach(function(doc) {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    //console.log(doc.data());
if(doc.data().user_id==id) 
a.push(doc.data())
a.sort((a,b)=>{
  if ( a.created_at < b.created_at ){
    return 1;
  }
  if ( a.created_at > b.created_at ){
    return -1;
  }
  return 0;
});
//console.log(a)
});//console.log(a)
this.orders=a;
}); 


};
sortByDate( a, b ) {
  if ( a.created_at < b.created_at ){
    return -1;
  }
  if ( a.created_at > b.created_at ){
    return 1;
  }
  return 0;
}

getupdateOrder(){
  
  this.db.collection("posts") .snapshotChanges()
  .pipe( map( actions=>{
           actions.map( a => 
{ 
const data = a.payload.doc.data();//console.log(data) ;
const id = a.payload.doc.id; 
//return {id, ...data}
}
)}
))
}
/*
   GetOrder(id: string) {
    this.OrderRef = this.db.object('order-list/' + id);
    return this.OrderRef;
  }

  // Fetch orders List
  GetOrdersList() {
    this.OrdersRef = this.db.list('orders-list');
    return this.OrdersRef;
  }  

  // Update order Object
  UpdateOrder(order:any) {
    this.OrderRef.update({
      status: order.status,
    })
  }  */
  commandes(id:any) {

    return this.db
            .collection<any>('posts', ref => {
              let query:
                | firebase.firestore.CollectionReference
                | firebase.firestore.Query = ref;
  
              query = query.where('user_id', '==', id);
  
              return query;
            })
            .snapshotChanges()
            .pipe(
              map(action =>
                action.map(a => {
                  const data = a.payload.doc.data();
                 // console.log(data)
                  const id = a.payload.doc.id;
                  return { id, ...data };
                }),
              ),
            );
  }
}
