import { Component, Inject } from '@angular/core';
import { FirebaseService } from './services/firebase.service';
import { Platform, NavController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';
import { UserService } from './services/user.service';
import { Subscription } from 'rxjs';
import { MyEvent } from 'src/services/myevent.services';
import { Constants } from 'src/models/contants.models';
import { VtPopupPage } from './vt-popup/vt-popup.page';
import { CategorieService } from './services/categorie.service';
import { PlatService } from './services/plat.service';
import{CookieService}from "ngx-cookie-service"
import {StripeFactoryService, StripeService} from 'ngx-stripe';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  rtlSide = "left";
  selectedIndex: any;
  appPages: any;
  jwt
  user
  private sub:Subscription
  constructor(  private stripeFactory: StripeFactoryService,private stripeService: StripeService,private userService:UserService,private cookies:CookieService,private fb:FirebaseService,private categorieService: CategorieService,private platService:PlatService,
    @Inject(APP_CONFIG) public config: AppConfig,
    private platform: Platform, private navCtrl: NavController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalController: ModalController,
    private translate: TranslateService, private myEvent: MyEvent) {
    this.initializeApp();
    this.myEvent.getLanguageObservable().subscribe(value => {
      this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      let defaultLang = window.localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
      this.globalize(defaultLang);
    });
  }

  globalize(languagePriority) {
    this.translate.setDefaultLang("en");
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = "rtl";
        break;
      }
      default: {
        this.rtlSide = "ltr";
        break;
      }
    }
  }

  ngOnInit() {
    this.platService.getInforestaurent().subscribe(d=>{
      this.platService.restoInfo=d;    
      });
this.platService.GetPublicKeyStripe().subscribe((d:any)=>{
  this.stripeService.changeKey(d.message)
  this.platService.stripe=  this.stripeFactory.create(d.message);

})
    this.categorieService.fetchCategories()
      this.platService.fetchPlats()

this.user=this.cookies.get("user")
if (this.user){
  this.sub= this. fb.commandes(JSON.parse(this.user).id).subscribe(data=>{
    this.fb.orders=data;
    this.userService.updatedOrders.next(data)
  });
  }

  this.userService.updatedUser.subscribe(data=>{
    this.user=this.cookies.get("user")
if (this.user&&!this.sub){
this.sub= this. fb.commandes(data).subscribe(data=>{  this.fb.orders=data;
this.userService.updatedOrders.next(data)
 // console.log(data);
});
}
else if (!this.user&& this.sub){       

  this.sub.unsubscribe(); this.sub=null;this.fb.orders=[]
  this.userService.updatedOrders.next([])
}})

    if (this.config.demoMode) {
      setTimeout(() => {
        this.presentModal();
      }, 15000)
    }


    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
 
  async presentModal() {
    const modal = await this.modalController.create({
      component: VtPopupPage,
    });
    return await modal.present();
  }
}
