import { NgModule } from "@angular/core";
import { Firebase } from "@ionic-native/firebase/ngx";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AngularFireModule } from "@angular/fire";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Geolocation } from "@ionic-native/geolocation/ngx";
import { CookieService } from "ngx-cookie-service";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicStorageModule } from "@ionic/storage-angular";
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,

} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
// import { BuyappalertPageModule } from '../buyappalert/buyappalert.page';
import { Drivers } from "@ionic/storage";
import { PayPal } from "@ionic-native/paypal/ngx";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import '@stripe/stripe-js';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { File, FileEntry } from "@ionic-native/File/ngx";
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from "@ionic-native/camera/ngx";
import { NgxStripeModule } from 'ngx-stripe';
import { FilePath } from "@ionic-native/file-path/ngx";
import { APP_CONFIG, BaseAppConfig } from "./app.config";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { environment } from "src/environments/environment";
import { env } from "./Models/env";
import { FirebaseService } from "./services/firebase.service";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {HeaderComponent} from './header/header.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {HeaderModule} from './header/Header.module';
import {FooterModule} from './footer/footer.module'; // <-- import the module

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [FontAwesomeModule, NgxStripeModule.forRoot(''),
    AngularFirestoreModule,
    IonicStorageModule.forRoot(),
    BrowserModule, SocialLoginModule,
    IonicModule.forRoot(), NgxPaginationModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    AngularFireModule.initializeApp(env.firebase),
    TranslateModule, FooterModule,
    IonicStorageModule.forRoot(),
    // BuyappalertPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }), HeaderModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('656735258742-32elnhoijjl1mgk1kkm4hrujq3hn5g5e.apps.googleusercontent.com') // your client id
          }
        ]
      } as SocialAuthServiceConfig
    },
    PayPal,
    
    Firebase,
    ,
    GooglePlus,
    Geolocation,
    NativeGeocoder,
    StatusBar,
    File,
    Camera,
    FilePath,
    WebView,
    SplashScreen,
    CookieService,
    FirebaseService,
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
