export class env {
  static apiURL:string='https://api.snack-leqg.fr/api/';

  static apiURLnoApi:string='https://api.snack-leqg.fr/';
  static firebase = {
    apiKey: "AIzaSyDXvQMPQtd0bWwlf7QeeIUxlOlZ6exIdL0",
    authDomain: "leqg-175f3.firebaseapp.com",
    projectId: "leqg-175f3",
    storageBucket: "leqg-175f3.appspot.com",
    messagingSenderId: "445692497702",
    appId: "1:445692497702:web:7d5c96da482d5691affbe7",
    measurementId: "G-6KVWRGR717"
    };
}
