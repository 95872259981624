import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import {FooterComponent} from '../footer/footer.component';


@NgModule({
    exports: [FooterComponent, FooterComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule, ],
    declarations: [FooterComponent]
})
export class FooterModule {}
