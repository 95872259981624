import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {SocialAuthService, SocialUser} from 'angularx-social-login';
import {ModalController, Platform} from '@ionic/angular';
import {CookieService} from 'ngx-cookie-service';
import {AngularFirestore} from 'angularfire2/firestore';
import {FirebaseService} from '../services/firebase.service';
import {Storage} from '@ionic/storage-angular';
import {OffreService} from '../services/offre.service';
import {Router} from '@angular/router';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {NativeGeocoder, NativeGeocoderOptions} from '@ionic-native/native-geocoder/ngx';
import {PlatService} from '../services/plat.service';
import {CategorieService} from '../services/categorie.service';
import {UserService} from '../services/user.service';
import {VariationSelectionPage} from '../variation-selection/variation-selection.page';
import {OffreSelectionPageComponent} from '../offre-selection-page/offre-selection-page.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  latlngorg: number[];
  latlng: any[];
  public sorted=[]
  orders: any;
  private jwt: any;
  private userGoogle: SocialUser;
  img_user: any;
  quantite: any=0;
  constructor(private socialAuthService:SocialAuthService,private platform: Platform,public cookieService:CookieService,private db: AngularFirestore,private firebaseserv:FirebaseService,private storage: Storage,private offreservice:OffreService,private route: Router,private geolocation: Geolocation,private geocoder: NativeGeocoder,public servicePlat:PlatService,private categorieService:CategorieService,private modalController: ModalController,private  userService:UserService) { }
  public plats=[]
  public searched:any[] =[]
  public categories=[]

  address: string[];
  ngOnInit() {
this.userService.updatedUser.subscribe(data=>
  {
    this.quantite=this.servicePlat.quantite;
    this.offreservice.quantite=0;})

    this.servicePlat.RestaurentWork().subscribe((d:any)=>{
      if(d==1)
       this.servicePlat.resto_isWorking=true
       else 
       this.servicePlat.resto_isWorking=false;
     })

    if(this.servicePlat.quantite!=0 ||this.offreservice.quantite!=0)
    this.quantite=this.servicePlat.quantite+this.offreservice.quantite
    this.servicePlat.PAYED.subscribe(data=>{
      if(data==true)
      {this.offreservice.quantite=0;this.servicePlat.quantite=0
        this.servicePlat.updatedQuantite.next(0)
    }
    })
    this.servicePlat.updatedQuantite.subscribe(data=>{
      this.quantite=data+this.offreservice.quantite
    })
    this.offreservice.updatedQuantite.subscribe(data=>{
      this.quantite=data+this.servicePlat.quantite
    })
    if(this.cookieService.check('jwt'))
    { 
      this.jwt=this.cookieService.get('jwt'); //console.log(this.jwt)

    if(this.cookieService.check('user'))
    this.img_user="https://www.api.msfood.msdigital34.fr/restaurant-backend/storage/app/public/"+ JSON.parse(this.cookieService.get('user')).img;

    //console.log(this.jwt)
      }
  }
  user_id(user_id: any) {
    throw new Error('Method not implemented.');
  }



  offers() {
    this.route.navigate(['./offers']);
  }
  stores() {
    this.route.navigate(['./stores']);
  }
  items(i:number) {
    this.categorieService.setSelected(i)
    this.route.navigate(['./items']);
  }

  recherche(event:string){//console.log(event)
this.servicePlat.updatedResearch.next(event)
  }


  secnnecter(){
    this.route.navigate(['phone-number'])
  }

  compte() {

  }
  terms_conditions() {
    this.route.navigate(['./terms-conditions']);
  }
  phone_number() {
    this.jwt=this.cookieService.get('jwt');
    this.userService.logout(this.jwt).subscribe((data) => {
          this.cookieService.deleteAll();
          localStorage.clear();

          this.socialAuthService.authState.subscribe(d=>{this.userGoogle=d;
           if(this.userGoogle)
          {
            this.socialAuthService.signOut(true);
          }
          
          });
          this.userService.updatedUser.next(null)
          this.servicePlat.resetCart();
          this.offreservice.resetCart();
          this.servicePlat.codeReduction=null;
          this.servicePlat.taux_reduction =0;
          this.servicePlat.PriceWithFee=0;
          this.servicePlat.setPrixTot(0);this.servicePlat.prixtotwithOffer=0;this.servicePlat.setidcodeRed(null);
          this.offreservice.prixTot=0;
          this.cookieService.deleteAll();
          localStorage.clear();
        },
        (e) =>this.route.navigate(['home']));
    this.route.navigate(['home']);
    localStorage.clear();            this.cookieService.deleteAll();


  }
  register() {
    this.route.navigate(['register']);
  }

  order() {
    this.route.navigate(['my-orders']);
  }
  termes(){
    this.route.navigate(['terms-conditions']);
  }
  GoToPanier(){
    this.route.navigate(['cart']);

  }
}
