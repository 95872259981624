import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Categorie } from '../Models/categorie';
import { env } from '../Models/env';
@Injectable({
  providedIn: 'root'
})
export class CategorieService {
 // private url=environment.apiUrl
 private env = env.apiURL;
  private categories:Categorie[] = [];
   selected=0
 updatedCategories = new Subject<Object[]>();
  
 constructor(private http: HttpClient) { }
 getCategories(){
  return this.categories.slice();
}
setSelected(value:number){
  this.selected=value
}

fetchCategories(){
   this.http.get<Object[]>(`${this.env}categorie`).subscribe((data:Categorie[])=>{this.categories=data
    
    this.updatedCategories.next(this.getCategories());
  } )
}
}
